"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { useState, useEffect } from "react";
import axios from "axios";
export const useSeekPayTypes = () => {
  const [payTypes, setPayTypes] = useState([]);
  useEffect(() => {
    const getPayTypes = () => __async(void 0, null, function* () {
      const payTypesStr = localStorage.getItem("payTypes");
      if (payTypesStr) {
        const payTypesFromLocalStorage = JSON.parse(payTypesStr);
        setPayTypes(payTypesFromLocalStorage);
        return;
      }
      try {
        const { data } = yield axios.get("/api/v4/seek/pay_types");
        const dataPayTypes = data.data.payTypes;
        setPayTypes(dataPayTypes);
        localStorage.setItem("payTypes", JSON.stringify(dataPayTypes));
      } catch (error) {
        console.log(error);
      }
    });
    getPayTypes();
  }, []);
  return { payTypes, setPayTypes };
};
