import React, { Dispatch, useState } from 'react';
import { Stack, Popover, Button, CircularProgress, Tooltip } from '@mui/material';
import {
  Menu as MenuIcon,
  Edit as EditIcon,
  Done as DoneIcon,
  Close as CloseIcon
} from '@mui/icons-material';
import { useMutation, useQueryClient, UseQueryResult } from '@tanstack/react-query';
import { IApplication } from '../types';
import { FormNumberField } from '../../Components/CustomUIElements/FormTextField';
import { styles } from '../styles';
import { sharedClasses } from '../../Components/CustomUIElements/sharedClasses';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy
} from '@dnd-kit/sortable';
import SortableSection from './SortableSection';
import { ApplicationState, ApplicationAction } from '../types';
import { defaultSections, getApplicationRatingColor } from '../config';
import Api from '../API';
import { theme } from '../../../../components/ThemeContext/ThemeObject';
import { IUserPermissions } from '../../Components/sharedTypes';

const RatingLabel = ({ label }: { label: string }) => (
  <Tooltip
    title={`${label} rating`}
    placement="bottom-start"
    PopperProps={{ modifiers: [{ name: 'offset', options: { offset: [8, -10] } }] }}
  >
    <Stack sx={{ color: '#333333' }}>{label}</Stack>
  </Tooltip>
);

export default function Ratings({
  ApplicationState,
  dispatch,
  hrQuestion
}: {
  ApplicationState: ApplicationState;
  dispatch: Dispatch<ApplicationAction>;
  hrQuestion: UseQueryResult<any, unknown>;
}) {
  const queryClient = useQueryClient();
  const application = queryClient.getQueryData<IApplication>(['application']);
  const permissions = queryClient.getQueryData<IUserPermissions>(['permissions']);
  const { sortableSections } = ApplicationState;
  const [editRating, setEditRating] = useState<{ interview: boolean; ref: boolean }>({
    interview: false,
    ref: false
  });
  const [interviewRating, setInterviewRating] = useState<string>(
    application?.ratings.interview_rating === null
      ? ''
      : String(application?.ratings.interview_rating)
  );
  const [refRating, setRefRating] = useState<string>(
    application?.ratings.ref_check_rating === null
      ? ''
      : String(application?.ratings.ref_check_rating)
  );
  const [ratingErrors, setRatingErrors] = useState<{ ref: boolean; interview: boolean }>({
    ref: false,
    interview: false
  });
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const { mutate: rateApplication, isLoading: ratingApplication } = useMutation({
    mutationFn: async () => {
      if (application) {
        const { res } = await Api.updateApplicationRating(application.job.id, application.id, {
          ref_check_rating: refRating ? refRating : null,
          interview_rating: interviewRating ? interviewRating : null
        });
        return res;
      }
    },
    onSuccess: (res) => {
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `${refRating ? 'Ref check' : 'Interview'} rating has been updated successfully`,
          state: 'success'
        }
      });
      queryClient.setQueryData(['application'], {
        ...res,
        surround_application: application?.surround_application
      });
      queryClient.invalidateQueries(['application']);
    },
    onError: (error: { error: string }) => {
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error in updating ${refRating ? 'ref check' : 'interview'} rating, ${error.error}`,
          state: 'error'
        }
      });
      setInterviewRating(String(application?.ratings.interview_rating));
      setRefRating(String(application?.ratings.ref_check_rating));
    }
  });

  const { mutate: updateUiPreferences } = useMutation({
    mutationFn: async (sections: string[]) => {
      if (application) {
        await Api.updateUiPreferences({ sorting: { order: sections }, source: 'application' });
      }
    },
    onError: (error: { error: string }) => {
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error saving your preferences, ${error.error}`,
          state: 'error'
        }
      });
    }
  });

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;

    if (active.id !== over?.id) {
      const oldIndex = sortableSections.findIndex((e) => e === active.id);
      const newIndex = sortableSections.findIndex((e) => e === over?.id);
      const newArray = arrayMove(sortableSections, oldIndex, newIndex);
      dispatch({ type: 'SET_SORTABLE_SECTIONS', payload: newArray });
      updateUiPreferences(newArray);
    }
  }

  const RatingValue = ({ value }: { value: number | null }) => {
    const displayValue = value === null ? 'Not rated' : `${value}%`;
    return (
      <Stack sx={{ fontWeight: 'bold', fontSize: '12px' }}>
        {ratingApplication ? (
          <CircularProgress size={15} color="inherit" sx={{ marginTop: 0.5 }} />
        ) : (
          displayValue
        )}
      </Stack>
    );
  };

  const filteredSortableSections = hrQuestion.data
    ? sortableSections
    : sortableSections.filter((t) => t !== 'Application questionnaire');

  const RatingErrorMessage = () => (
    <Stack sx={{ color: theme.palette.error.main, fontSize: '8px' }}>
      Number must be between 0-100
    </Stack>
  );

  if (!application) return null;

  return (
    <Stack sx={{ flexDirection: 'row', columnGap: 1 }}>
      <Stack sx={{ flexDirection: 'row', width: '100%', justifyContent: 'space-evenly' }}>
        {!permissions?.Applications?.['Hide Overall Rating'] && (
          <Stack
            sx={{
              ...styles.ratingCard,
              ...getApplicationRatingColor(application.ratings.overall_rating)
            }}
          >
            <RatingLabel label="Overall" />
            <RatingValue value={application.ratings.overall_rating} />
          </Stack>
        )}
        <Stack
          sx={{
            ...styles.ratingCard,
            ...getApplicationRatingColor(application.ratings.qa_rating)
          }}
        >
          <RatingLabel label="Q/A" />
          <RatingValue value={application.ratings.qa_rating} />
        </Stack>
        <Stack
          sx={{
            ...styles.ratingCard,
            ...getApplicationRatingColor(application.ratings.interview_rating)
          }}
        >
          <RatingLabel label="Interview" />
          <Stack sx={styles.editableRatings}>
            {editRating.interview ? (
              <Stack>
                <Stack sx={styles.ratingInput}>
                  <FormNumberField
                    value={interviewRating}
                    onChange={(e) => setInterviewRating(e.target.value)}
                    styles={styles.editRating}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        if (Number(interviewRating) >= 0 && Number(interviewRating) <= 100) {
                          rateApplication();
                          setEditRating({ ...editRating, interview: !editRating.interview });
                        } else {
                          setRatingErrors({ ...ratingErrors, interview: true });
                        }
                      }
                    }}
                    placeholder="e.g.100"
                  />
                  {ratingApplication ? (
                    <CircularProgress size={15} />
                  ) : (
                    <DoneIcon
                      id="save-interview-rating-button"
                      sx={{ color: '#5BC4C0', cursor: 'pointer', fontSize: '16px' }}
                      onClick={() => {
                        if (Number(interviewRating) >= 0 && Number(interviewRating) <= 100) {
                          rateApplication();
                          setEditRating({ ...editRating, interview: !editRating.interview });
                        } else {
                          setRatingErrors({ ...ratingErrors, interview: true });
                        }
                      }}
                    />
                  )}
                  <CloseIcon
                    sx={{ ...styles.clickableGreyIcon, fontSize: '16px' }}
                    onClick={() => {
                      setEditRating({ ...editRating, interview: !editRating.interview });
                      setRatingErrors({ ...ratingErrors, interview: false });
                      setInterviewRating(
                        application.ratings.interview_rating === null
                          ? ''
                          : String(application.ratings.interview_rating)
                      );
                    }}
                  />
                </Stack>
                {ratingErrors.interview && <RatingErrorMessage />}
              </Stack>
            ) : (
              <Stack sx={{ flexDirection: 'row', columnGap: 1, alignItems: 'center' }}>
                <RatingValue value={application.ratings.interview_rating} />
                {permissions?.Applications?.['Add Advanced Ratings'] &&
                  permissions.Applications?.['Rate Applications'] &&
                  !editRating.interview &&
                  !application.original_job && (
                    <EditIcon
                      id="edit-interview-rating-button"
                      sx={{ cursor: 'pointer' }}
                      onClick={() => {
                        setEditRating({ ...editRating, interview: !editRating.interview });
                      }}
                    />
                  )}
              </Stack>
            )}
          </Stack>
        </Stack>
        <Stack
          sx={{
            ...styles.ratingCard,
            ...getApplicationRatingColor(application.ratings.ref_check_rating)
          }}
        >
          <RatingLabel label="Ref check" />
          <Stack sx={styles.editableRatings}>
            {editRating.ref ? (
              <Stack>
                <Stack sx={styles.ratingInput}>
                  <FormNumberField
                    value={refRating}
                    onChange={(e) => setRefRating(e.target.value)}
                    styles={styles.editRating}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        if (Number(refRating) >= 0 && Number(refRating) <= 100) {
                          rateApplication();
                          setEditRating({ ...editRating, ref: !editRating.ref });
                        } else {
                          setRatingErrors({ ...ratingErrors, ref: true });
                        }
                      }
                    }}
                    placeholder="e.g.100"
                  />
                  {ratingApplication ? (
                    <CircularProgress size={15} />
                  ) : (
                    <DoneIcon
                      id="save-ref-check-rating-button"
                      sx={{ color: '#5BC4C0', cursor: 'pointer', fontSize: '16px' }}
                      onClick={() => {
                        if (Number(refRating) >= 0 && Number(refRating) <= 100) {
                          rateApplication();
                          setEditRating({ ...editRating, ref: !editRating.ref });
                        } else {
                          setRatingErrors({ ...ratingErrors, ref: true });
                        }
                      }}
                    />
                  )}
                  <CloseIcon
                    sx={{ ...styles.clickableGreyIcon, fontSize: '16px' }}
                    onClick={() => {
                      setEditRating({ ...editRating, ref: !editRating.ref });
                      setRatingErrors({ ...ratingErrors, ref: false });
                      setRefRating(
                        application.ratings.ref_check_rating === null
                          ? ''
                          : String(application.ratings.ref_check_rating)
                      );
                    }}
                  />
                </Stack>
                {ratingErrors.ref && <RatingErrorMessage />}
              </Stack>
            ) : (
              <Stack sx={{ flexDirection: 'row', columnGap: 1, alignItems: 'center' }}>
                <RatingValue value={application.ratings.ref_check_rating} />
                {permissions?.Applications?.['Add Advanced Ratings'] &&
                  permissions.Applications?.['Rate Applications'] &&
                  !editRating.ref &&
                  !application.original_job && (
                    <EditIcon
                      id="edit-ref-check-rating-button"
                      sx={{ cursor: 'pointer' }}
                      onClick={() => {
                        setEditRating({ ...editRating, ref: !editRating.ref });
                      }}
                    />
                  )}
              </Stack>
            )}
          </Stack>
        </Stack>
      </Stack>
      <Stack
        id="sections-button"
        sx={{
          ...styles.sortableSectionsButton,
          backgroundColor: anchorEl ? 'rgba(8,77,109,0.121)' : '#FFFFFF'
        }}
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <MenuIcon />
      </Stack>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        sx={{ marginTop: 1 }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <Stack sx={{ padding: 3, rowGap: 1 }}>
          <Stack sx={styles.sortableSectionsHeader}>
            <Stack sx={{ fontWeight: 'bold' }}>Sections</Stack>
            <Button
              id="reset-sections-button"
              sx={{ ...sharedClasses.genericButton, padding: '2px 12px', borderRadius: '4px' }}
              onClick={() => {
                dispatch({ type: 'SET_SORTABLE_SECTIONS', payload: defaultSections });
                updateUiPreferences(defaultSections);
              }}
            >
              Reset
            </Button>
          </Stack>
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
          >
            <SortableContext items={sortableSections} strategy={verticalListSortingStrategy}>
              {filteredSortableSections
                .filter((s) =>
                  permissions?.Applications?.['Add Suitability Comment'] ? s : s !== 'Notepad'
                )
                .map((section, index) => (
                  <SortableSection key={index} section={section} />
                ))}
            </SortableContext>
          </DndContext>
        </Stack>
      </Popover>
    </Stack>
  );
}
